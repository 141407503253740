import React from "react";
import MenuLinks from "./MenuLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const MenuNormal = () => {
  return (
    <nav className="menu">
      <Link to="/" id="home">
        <FontAwesomeIcon icon={faHome} />
      </Link>
      <MenuLinks className="menu_links_normal" />
    </nav>
  );
};

export default MenuNormal;
