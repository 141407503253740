import React from "react";
import { NavLink } from "react-router-dom";

function MenuLinks(props) {
  const menuElements = [
    {
      idList: "01",
      name: "Quienes Somos",
      url: "/quienes-somos",
      specialClass: "",
    },
    {
      idList: "02",
      name: "Soporte",
      url: "/soporte",
      specialClass: "",
    },
    {
      idList: "03",
      name: "Trabaja con Nosotros",
      url: "/trabaja-con-nosotros",
      specialClass: "",
    },
    {
      idList: "04",
      name: "Contacto",
      url: "/contactanos",
      specialClass: "",
    },
  ];

  return (
    <>
      <ol className={props.className}>
        {menuElements.map((element) => {
          return (
            <li key={element.idList}>
              {/* <a href={element.url}>{element.name}</a> */}
              <NavLink
                activeclassname="active"
                to={element.url}
                onClick={props.closeMenu}
              >
                {element.name}
              </NavLink>
            </li>
          );
        })}
      </ol>
    </>
  );
}

export default MenuLinks;
