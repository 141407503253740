import React from "react";
import logoArticle from "../images/soporte.jpg";
import { Link } from "react-router-dom";
import "../styles/articles.css";

const Suport = () => {
  return (
    <>
      <article>
        <h1 className="generalTittle">Soporte</h1>
        <main className="article">
          <img
            src={logoArticle}
            alt="quienes-somos"
            className="article-image"
          />
          <div className="article-det">
            <h4>
              ¿ Qué debo hacer para obtener una cuenta de acceso si ya soy
              cliente de su empresa ?
            </h4>
            <p>
              No todos los clientes de Capacity-soft requieren tener cuenta de
              usuario en nuestro portal, ya que la mayoría de nuestros servicios
              de soporte son realizados de manera directa y personalizada.
              Unicamente para casos de preguntas frecuentes relacionadas con:
              SiGeM Business Software, Sistemas o Equipos Biométricos de Huella
              Digital, Soluciones y Portales Web o Desarrollo a Medida, le será
              asignada de manera directa una cuenta de usuario con su
              correspondiente contraseña. En caso de que Ud. sea un cliente de
              cualquiera de los productos y servicios mencionados anteriormente,
              y no se le haya asigando una cuenta de usuario, le pedimos que se
              ponga en contacto con nosotros para asignarle una tan pronto como
              sea posible. Para todos los demás tipos de clientes, se aplicará
              el mecanismo de soporte más adecuado para cada caso.
            </p>

            <h4>¿ Tiene algún costo el uso de mi cuenta de usuario ?</h4>
            <p>
              No, no tiene costo alguno, ya que por el hecho de ser nuestro
              cliente, tendrá derecho a estar informado permanentemente de
              nuestras novedades, actualizaciones, renovaciones y cambios
              tecnológicos, conforme vayan presentándose. Solamente facturamos a
              nuestros clientes en caso de requerir soporte presencial o
              asesoría personalizada para resolver situaciones puntuales o
              especiales.
            </p>
            <h4>¿ Tiene algún tiempo de validez mi cuenta de usuario ?</h4>
            <p>
              Depende del caso. Tal como se ha mencionado en la primera
              pregunta, las cuentas de usuario se asignan a un grupo específico
              de clientes, generalmente, cuando se trata de clientes frecuentes
              o de ciertos servicios especiales que requieren una constante
              retro-alimentación y sinergia por parte de Jasvir Data-Systems. En
              el momento en que ya no sea necesaria esa forma de soporte, es
              decir, cuando el cliente no haya hecho uso de su cuenta de acceso
              a nuestro portal por un período de 10 meses o más, será dada de
              baja su cuenta de usuario, previo aviso por correo.
            </p>
          </div>

          <Link to="/contactanos" className="btn">
            Contactanos!
          </Link>
        </main>
      </article>
    </>
  );
};

export default Suport;
